import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import Layout from '../components/Layout'
import { Card, CardGrid } from '../components/Card'
import { H1, H2 } from '../components/Titles'
export default () => (
  <Layout>
    <H1>Benefits</H1>
    <CardGrid minCardWidth="500px">
      <Card>
    <H2>Aerial vs Satellite</H2>
    <p>The advantages of Aerial vs Satellite Relay are</p>
    <ul>
      <li>Close to zero delay</li>
      <li>
        Our sources can move, being tracked by the reception antennas. This is
        impossible using Satellite.
      </li>
      <li>
        The aircrafts, flying at altitudes up to 28,000ft, are almost never
        influenced by weather conditions
      </li>
      <li>
        We can transmit up to 6,5 hours&nbsp;continuously, in some cases even
        longer depending from height and speed
      </li>
    </ul>
</Card>
<Card>
    <H2>Aerial relay vs Static RF Systems</H2>
    <ul>
      <li>
        The installation &amp; dismantling time for static reception requires
        many days with a large team of people
      </li>
      <li>
        Cost to install a net of static RF receivers is far higher equipment
        wise
      </li>
      <li>The cables on the ground can be damaged by public</li>
      <li>Static reception can never exceed distances over 20-30 km</li>
    </ul>
    </Card>
    </CardGrid>
  </Layout>
)
